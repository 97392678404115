.outer-container{
    height: auto;
    width: 90%;
    background-color: #e3e3e3;
    margin-left: 4%;
    padding: 15px;
    border-radius: 10px;
}

.table-container {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #000;
    background-color: #fff;
  }

  .custom-table thead{
    font-size: 15pt;
    color: #426BBC;
    text-align: center;
  }

  .custom-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: wrap;
    font-weight: bold;
  }
  
  .custom-table th,
  .custom-table td {
    font-size: 12pt;
    padding: 8px;
    border: 1px solid #000;
    text-align: center;
    align-items: center;
  }

  .custom-table th{
    font-size: 15pt;
  }

  .custom-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  .custom-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  .rounded-cell-left{
    border-top-left-radius: 8px;
  }

  .rounded-cell-right{
    border-top-right-radius: 8px;
  }

  .custom-table button{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    color: #fff;
    font-weight: bold;
    font-size: 12pt;
    justify-self: center;
    height: 40px;
    margin-left: 20px;
  }

  .custom-table a{

    color: #426BBC;
    text-decoration: underline;
  }