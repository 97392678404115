.main-page-containers {
  flex-wrap: wrap;
  display: flex;
  padding-right: 3%;
  padding-left: 3%;
  padding-top: 5%;
  padding-bottom: 10%;
  gap: 10px;
}

.main-text{
  text-decoration: none;
  font-size: 21pt;
  font-weight: bold;
  color:rgb(0, 0, 0);
}

.Upload{
  min-width: 550px;
  width: 60%;
  height: auto;
  border-radius: 30px;
  margin: auto;
  background-color: #e3e3e3;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
  justify-items: center;
}


.input-upload{
  padding: 20px;
  width: 60%;
  height: 100px;
  border: none;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}

.input-upload::placeholder{
  align-self: center;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  font-size: 15pt;
  text-align: center;
}

.file-upload-container{
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  padding-bottom: 30px;
  width: 60%;
  height: 100px;
  background-color: #fff;
  margin-bottom: 40px;
  border-radius: 10px;
}

.input-upload{
  margin-top: 20px;
}

.choose-file-upload{
  margin-top: 20px;
  margin-left: -60px;
}

.cloud-and-file-upload{
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.cloud-image{
  width: 90px;
  height: 70px;
  margin-top: 0%;
  margin-left: -20px;
}

.submit-text-button{
  color: #fff;
  font-weight: bold;
  font-size: 13pt;
  width: 200px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}

.submit-text-button:enabled{
  background: linear-gradient(to right, #426BBC, #74A9F5);
}

.submit-text-button:disabled{
  background: linear-gradient(to right, #868686, #b6b6b6);
}

.checkbox-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.checkbox{
  margin:10px;
}

.checkbox-and-label{
  margin: 0px 0px 10px 10px;
}

.credit-reminder{
  font-size: 13pt;
  font-weight: 500;
  margin-top: -20px;
  margin-bottom: 25px;
}

.message-outer-container{
  display: flex;
  flex-direction: row;
  height: auto;
  width:fit-content;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  border-radius: 5px;
}

.failure-box{
  background-color: rgb(246, 41, 41);
  height:30px;
  width: 30px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.success-box{
  background-color: rgb(50, 139, 25);
  height:30px;
  width: 30px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.icon{
  color: #fff;
  font-weight: bold;
  margin-top: 5px;
}

.message-outer-container .message{
  font-size: 13pt;
  font-weight: bold;
}