.navbar {
    background-color: #fff;
    text-align: center;
    min-width: 600px;
    flex-direction: row;
    height: 12vh;
  }
  
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  
  li {
    margin: auto 20px;
    padding: auto;
  }
  
  .nav-link {
    color: #426BBC;
    text-decoration: none;
    font-size: 17pt;
    font-weight: bold;
  }
  
  .nav-link-active {
    color: #74A9F5;
    
    text-decoration: none;
    font-size: 17pt;
    font-weight: bold;
  }

  img{
    width: 100px;
    height: 80px;
    justify-content: flex-start;
    margin-left: 10px;

  }
  
  button{
    width: 115px;
    height: 50px;
    border-color: transparent transparent;
    background-color: #426BBC;
    line-height: 0rem;
    border-radius: 10px;
    margin-right: 15px;
    text-align: center;
  }

  .log-in{
    color: #fff;
    font-weight: bold;
    font-size: 17pt;
    z-index: 1;
  }

  .logged-in-icon{
    width: auto;
    height: 50px;
    border-color: transparent transparent;
    background-color: #426BBC;
    border-radius: 10px;
    margin-right: 15px;
    text-align: center;
    line-height: 0rem;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
  }