.placeholder{
    height: 100%;
}

.header-text{
    font-size: 30pt;
    color: #fff;
    font-weight: bold;
    justify-content: center;
}

.info-container{
    width: 450px;
    height: auto;
    border-radius: 30px;
    margin: auto;
    background-color: #eeeeee;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    padding: 20px;
}


.info-container .user-info{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 40px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
}

.info-container .field-container text{
    font-weight: bold;
}

.info-container .user-info text{
    margin-top: auto;
}

.credits{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 10px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
    margin-left: 60px;
}

.email{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 10px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
    margin-left: 77px;
}

.account-type{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 10px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
    margin-left: 15px;
}

.info-container .field-container{
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
}