.login-background {
    background-color: linear-gradient(to right, #426BBC, #74A9F5);
    flex-wrap: wrap;
  }
  
  .login-background::after{
    content:"";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #426BBC, #74A9F5);
    width: 100%;
    min-width: 650px;
    min-height: 760px;
    height: 100%;
    clip-path: polygon(500px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 400px);
    justify-content: center;
  }

  .login-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    width: 35%;
    height: auto;
    border-radius: 30px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    margin-left: 33%;
    margin-top: 170px;
    margin-right: auto;
    padding-bottom: 30px;
  }

  .signup-outer-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    width: 35%;
    height: 575px;
    border-radius: 30px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    margin-left: 35%;
    margin-top: 150px;
    margin-right: auto;
  }

  .pwd-reset-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    width: 35%;
    height: auto;
    border-radius: 30px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    margin-left: 35%;
    margin-top: 250px;
    padding-bottom: 30px;
  }

  .logo{ 
    display: flex;
    position: absolute;
    top: 30px;
    left: 30px;
    width: 200px;
    height: 160px;
  }

  .title-font{
    display: flex;
    color: #fff;
    font-size: 50pt;
    margin-left: 50px;
    margin-top: 150px;
    font-weight: bold;
    width: 450px;
  }

  .back-to-home-btn{
    display: flex;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 200px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  }

  .bth-text{
    color: #426BBC;
    font-size: 17pt;
    font-weight: bold;
  }

  .login-text{
    font-size: 30pt;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
  }

  .login-inputs{
    width: 80%;
    height: 50px;
    border: none;
    border-radius: 10px;
    background-color: #e8e8e8;
    margin-bottom: 30px;
    text-align: center;
    margin-left: 9%;
    font-size: 13pt;
    font-weight: bold;
  }

  .login-inputs::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    font-size: 15pt;
    text-align: center;
    line-height: normal;
    font-weight: bold;
  }

  .forgot-password-link{
    color: #000;
    font-size: 13pt;
    font-weight: bold;
    margin-top: -25px;
    margin-left: 9%;
    margin-bottom: 5px;
    text-decoration: none;
  }

  .login-button{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    width: 81%;
    margin-left: 9%;
  }

  .signup-button{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    width: 81%;
    margin-left: 9%;
    margin-bottom: 30px;
  }

  .login-btn-text{
    font-size: 15pt;
    color: #fff;
    font-weight: bold;
  }

  .sign-up-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-left: 9%;
  }

  .resend-code-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-left: 9%;
    margin-top: -25px;
    margin-bottom: 20px;
  }

  .no-account-text{
    font-size: 13pt;
    font-weight: bold;
    margin-top: 25px;
    margin-right: 15px;
  }

  .sign-up-link{
    color: #426BBC;
    text-decoration: none;
    font-size: 17pt;
    font-weight: bold;
    margin-top: 11px;
  }

  .password-reset-title{
    font-size: 25pt;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
  }

  .error-message{
    margin-left: 9%;
    margin-bottom: 30px;
    margin-top: -10px;
    font-size: 12pt;
    font-weight: bold;
    color: #426BBC;
  }