footer{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    min-width: 600px;
    height: 7vh;
    background-color: #426BBC;
    justify-content: space-between;
    margin-top: auto;
  }

footer nav{
  justify-content: center;
}

footer ul{
  margin-left: 10px;
  display: flex;
}


.footer-links{
  color: #fff;
  text-decoration: none;
  margin-left: 15px;
  margin-right: 15px;
}

footer text{
  color: #fff;
}

footer div{
  align-self: center;
  margin-right: 20px;
}

footer div span{
  margin-right: 10px;
  color: #fff;
}

a{
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}