.user-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 200px;
    z-index: 9999;
    border-radius: 10px;
    margin-right: 10px;
  }
  
  .user-menu-ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding:0;
    margin: 0;
    width: 150px;
  }
  
  .user-menu-li{
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    color: #426BBC;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

  .user-menu-li-logout{
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #426BBC;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

  .link {
    margin-right:60px;
    width: 100%;
    text-decoration: none;
  }

  .user-menu-li:hover{
    width: 100%;
    padding: 20px;
    background: #426BBC;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    color: #fff;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

  .user-menu-li-logout:hover{
    width: 100%;
    padding: 20px;
    background: #426BBC;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 50px;
    color: #fff;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }
