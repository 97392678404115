body{
  min-height: 81vh;
    display: flex;
    flex-direction: column;
}

.main{
  flex: 1;
}

.background-one {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-one::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #426BBC, #74A9F5);
  width: 100%;
  min-width: 600px;
  height: 85%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%);
}

.background-two{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-two::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #426BBC, #74A9F5);
  width: 100%;
  min-width: 595px;
  height: 85%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 50%);
}

.background-three{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-three::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #b8b8b86f, #e9e9e9);
  width: 100%;
  min-width: 595px;
  height: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 55%);
}

.background-four{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-four::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #b8b8b86f, #e9e9e9);
  width: 100%;
  min-width: 595px;
  height: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 0% 85%);
}

.background-five{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-five::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #426BBC, #74A9F5);
  width: 100%;
  min-width: 595px;
  height: 85%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
}

.hidden {
  display: none;
}