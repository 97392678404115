.header-text{
    font-size: 30pt;
    color: #fff;
    font-weight: bold;
    justify-content: center;
  }

.header-text-h2 {
    font-size: 22pt;
    color: #fff;
    font-weight: bold;
    justify-content: center;
  }


.footer-text {
    font-size: 12pt;
    color: #fff;
    font-weight: normal;
    justify-content: center;
    margin: 12px;
  }