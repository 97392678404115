body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  min-height: 81vh;
    display: flex;
    flex-direction: column;
}

.main{
  flex: 1 1;
}

.background-one {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-one::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #426BBC, #74A9F5);
  width: 100%;
  min-width: 600px;
  height: 85%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%);
}

.background-two{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-two::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #426BBC, #74A9F5);
  width: 100%;
  min-width: 595px;
  height: 85%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 50%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 50%);
}

.background-three{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-three::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #b8b8b86f, #e9e9e9);
  width: 100%;
  min-width: 595px;
  height: 100%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 55%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 55%);
}

.background-four{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-four::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #b8b8b86f, #e9e9e9);
  width: 100%;
  min-width: 595px;
  height: 100%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 0% 85%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 0% 85%);
}

.background-five{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.background-five::after{
  content:"";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to right, #426BBC, #74A9F5);
  width: 100%;
  min-width: 595px;
  height: 85%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
}

.hidden {
  display: none;
}
.main-page-containers {
  flex-wrap: wrap;
  display: flex;
  padding-right: 3%;
  padding-left: 3%;
  padding-top: 5%;
  padding-bottom: 10%;
  grid-gap: 10px;
  gap: 10px;
}

.main-text{
  text-decoration: none;
  font-size: 21pt;
  font-weight: bold;
  color:rgb(0, 0, 0);
}

.Upload{
  min-width: 550px;
  width: 60%;
  height: auto;
  border-radius: 30px;
  margin: auto;
  background-color: #e3e3e3;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
  justify-items: center;
}


.input-upload{
  padding: 20px;
  width: 60%;
  height: 100px;
  border: none;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}

.input-upload::-webkit-input-placeholder{
  align-self: center;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  font-size: 15pt;
  text-align: center;
}

.input-upload::placeholder{
  align-self: center;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  font-size: 15pt;
  text-align: center;
}

.file-upload-container{
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  padding-bottom: 30px;
  width: 60%;
  height: 100px;
  background-color: #fff;
  margin-bottom: 40px;
  border-radius: 10px;
}

.input-upload{
  margin-top: 20px;
}

.choose-file-upload{
  margin-top: 20px;
  margin-left: -60px;
}

.cloud-and-file-upload{
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.cloud-image{
  width: 90px;
  height: 70px;
  margin-top: 0%;
  margin-left: -20px;
}

.submit-text-button{
  color: #fff;
  font-weight: bold;
  font-size: 13pt;
  width: 200px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}

.submit-text-button:enabled{
  background: linear-gradient(to right, #426BBC, #74A9F5);
}

.submit-text-button:disabled{
  background: linear-gradient(to right, #868686, #b6b6b6);
}

.checkbox-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.checkbox{
  margin:10px;
}

.checkbox-and-label{
  margin: 0px 0px 10px 10px;
}

.credit-reminder{
  font-size: 13pt;
  font-weight: 500;
  margin-top: -20px;
  margin-bottom: 25px;
}

.message-outer-container{
  display: flex;
  flex-direction: row;
  height: auto;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  border-radius: 5px;
}

.failure-box{
  background-color: rgb(246, 41, 41);
  height:30px;
  width: 30px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.success-box{
  background-color: rgb(50, 139, 25);
  height:30px;
  width: 30px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.icon{
  color: #fff;
  font-weight: bold;
  margin-top: 5px;
}

.message-outer-container .message{
  font-size: 13pt;
  font-weight: bold;
}
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #426BBC;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

.header{
    font-size: 30pt;
    font-weight: bold;
    justify-content: center;
    color: #426BBC;
}

.explanation-section{
    display: flex;
    justify-content: center;
}

.explanation-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 65%;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius: 25px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.6);
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}

.explanation-title{
    font-size: 20pt;
    font-weight: bold;
    color: #426BBC;
}

.levels-container{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    height: auto;
    width: auto;
    margin: 50px;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

.levels-list{
    text-align: left;
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
    padding: 10px;
    justify-content: space-around;
}

.levels-list li{
    font-size: 17pt;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
}

.sample-header{
    color: #000;
    font-size: 20pt;
    font-style: italic;
    font-weight: bold;
    text-align: center;
}

.level-example-container{
    display: flex;
    align-items: left;
    margin-left: 7%;
    margin-right: 7%;
}

.level-example-list{
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}

.level-example-list li{
    font-size: 20pt;
    font-weight: bold;
    color: #426BBC;
}

.level-example-list p{
    color: #000;
    font-size: 13pt;
}

.all-modules-container{
    display: flex;
    justify-content: center;
}

.module-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 65%;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.6);
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    margin-left: -50px;
}

.modules-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.module-container li{
    font-size: 20pt;
    font-weight: bold;
    color: #426BBC;
}

.module-container p{
    color: #000;
}

.about-us-container{
    display: flex;
    justify-content: center;
}

.about-us-container div{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 65%;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.6);
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}

.about-us-container p{
    color: #000;
    font-size: 15pt;
}
.header {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  
  .header.expanded .triangle {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  
  .triangle {
    margin-right: 10px;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    color: #426BBC;
    font-size: 20px;
    line-height: 1; 
  }
.description-text-plans a {
  color:#426BBC !important;
}
.main-page-containers {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 5%;
    padding-bottom: 10%;
    grid-gap: 8px;
    gap: 8px;
}

.header-text-plans{
  font-size: 30pt;
  color: #426BBC;
  font-weight: bold;
  justify-content: center;
}

.box-and-text{
  margin: auto;
  width: 600px;
}

.main-text{
  text-decoration: none;
  font-size: 21pt;
  font-weight: bold;
  color:rgb(0, 0, 0);
}

.plan-container-free{
  width: 285px;
  height: auto;
  min-height: 225px;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
}

.description-text-plans {
  width: 65%;
  height: auto;
  min-height: 175px;
  border-radius: 15px;
  margin: auto;  
  background-color: #ffffff;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 40px;
}

.plan-container {
  width: 285px;
  height: auto;
  min-height: 225px;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 30px;
  background-color: #dde8ff;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
}

.plan-container-basic{
  width: 285px;
  height: auto;
  min-height: 225px;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 30px;
  background-color: #dde8ff;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
}

.plan-container-advanced{
  width: 285px;
  height: auto;
  min-height: 225px;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 30px;
  background: #a2c1ff;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
}

.plan-container-professional{
  width: 285px;
  height: auto;
  min-height: 225px;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 30px;
  background:  #5e86d8;
  box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  padding: 10px;
}

.plan-title {
  font-size: 25pt;
  font-weight: bold;
}

.plan-list > ul {
  font-size: 13pt;
  display: block;
  list-style: disc;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.price {
  font-size: 1.5em;
  font-weight: bolder;
}
.price-interval {
  font-size: 0.8em;
}
hr {
  border: 1.5px solid black;
}
.login-background {
    background-color: linear-gradient(to right, #426BBC, #74A9F5);
    flex-wrap: wrap;
  }
  
  .login-background::after{
    content:"";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #426BBC, #74A9F5);
    width: 100%;
    min-width: 650px;
    min-height: 760px;
    height: 100%;
    -webkit-clip-path: polygon(500px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 400px);
            clip-path: polygon(500px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 400px);
    justify-content: center;
  }

  .login-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    width: 35%;
    height: auto;
    border-radius: 30px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    margin-left: 33%;
    margin-top: 170px;
    margin-right: auto;
    padding-bottom: 30px;
  }

  .signup-outer-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    width: 35%;
    height: 575px;
    border-radius: 30px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    margin-left: 35%;
    margin-top: 150px;
    margin-right: auto;
  }

  .pwd-reset-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    width: 35%;
    height: auto;
    border-radius: 30px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    margin-left: 35%;
    margin-top: 250px;
    padding-bottom: 30px;
  }

  .logo{ 
    display: flex;
    position: absolute;
    top: 30px;
    left: 30px;
    width: 200px;
    height: 160px;
  }

  .title-font{
    display: flex;
    color: #fff;
    font-size: 50pt;
    margin-left: 50px;
    margin-top: 150px;
    font-weight: bold;
    width: 450px;
  }

  .back-to-home-btn{
    display: flex;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 200px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
  }

  .bth-text{
    color: #426BBC;
    font-size: 17pt;
    font-weight: bold;
  }

  .login-text{
    font-size: 30pt;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
  }

  .login-inputs{
    width: 80%;
    height: 50px;
    border: none;
    border-radius: 10px;
    background-color: #e8e8e8;
    margin-bottom: 30px;
    text-align: center;
    margin-left: 9%;
    font-size: 13pt;
    font-weight: bold;
  }

  .login-inputs::-webkit-input-placeholder{
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    font-size: 15pt;
    text-align: center;
    line-height: normal;
    font-weight: bold;
  }

  .login-inputs::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    font-size: 15pt;
    text-align: center;
    line-height: normal;
    font-weight: bold;
  }

  .forgot-password-link{
    color: #000;
    font-size: 13pt;
    font-weight: bold;
    margin-top: -25px;
    margin-left: 9%;
    margin-bottom: 5px;
    text-decoration: none;
  }

  .login-button{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    width: 81%;
    margin-left: 9%;
  }

  .signup-button{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    width: 81%;
    margin-left: 9%;
    margin-bottom: 30px;
  }

  .login-btn-text{
    font-size: 15pt;
    color: #fff;
    font-weight: bold;
  }

  .sign-up-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-left: 9%;
  }

  .resend-code-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-left: 9%;
    margin-top: -25px;
    margin-bottom: 20px;
  }

  .no-account-text{
    font-size: 13pt;
    font-weight: bold;
    margin-top: 25px;
    margin-right: 15px;
  }

  .sign-up-link{
    color: #426BBC;
    text-decoration: none;
    font-size: 17pt;
    font-weight: bold;
    margin-top: 11px;
  }

  .password-reset-title{
    font-size: 25pt;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
  }

  .error-message{
    margin-left: 9%;
    margin-bottom: 30px;
    margin-top: -10px;
    font-size: 12pt;
    font-weight: bold;
    color: #426BBC;
  }
.navbar {
    background-color: #fff;
    text-align: center;
    min-width: 600px;
    flex-direction: row;
    height: 12vh;
  }
  
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  
  li {
    margin: auto 20px;
    padding: auto;
  }
  
  .nav-link {
    color: #426BBC;
    text-decoration: none;
    font-size: 17pt;
    font-weight: bold;
  }
  
  .nav-link-active {
    color: #74A9F5;
    
    text-decoration: none;
    font-size: 17pt;
    font-weight: bold;
  }

  img{
    width: 100px;
    height: 80px;
    justify-content: flex-start;
    margin-left: 10px;

  }
  
  button{
    width: 115px;
    height: 50px;
    border-color: transparent transparent;
    background-color: #426BBC;
    line-height: 0rem;
    border-radius: 10px;
    margin-right: 15px;
    text-align: center;
  }

  .log-in{
    color: #fff;
    font-weight: bold;
    font-size: 17pt;
    z-index: 1;
  }

  .logged-in-icon{
    width: auto;
    height: 50px;
    border-color: transparent transparent;
    background-color: #426BBC;
    border-radius: 10px;
    margin-right: 15px;
    text-align: center;
    line-height: 0rem;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
  }
.user-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 200px;
    z-index: 9999;
    border-radius: 10px;
    margin-right: 10px;
  }
  
  .user-menu-ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding:0;
    margin: 0;
    width: 150px;
  }
  
  .user-menu-li{
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    color: #426BBC;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

  .user-menu-li-logout{
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #426BBC;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

  .link {
    margin-right:60px;
    width: 100%;
    text-decoration: none;
  }

  .user-menu-li:hover{
    width: 100%;
    padding: 20px;
    background: #426BBC;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    color: #fff;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

  .user-menu-li-logout:hover{
    width: 100%;
    padding: 20px;
    background: #426BBC;
    box-shadow: 0 6px 7px rgba(0,0,0, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 50px;
    color: #fff;
    text-decoration: none;
    font-size: 15pt;
    font-weight: bold;
  }

.placeholder{
    height: 100%;
}

.header-text{
    font-size: 30pt;
    color: #fff;
    font-weight: bold;
    justify-content: center;
}

.info-container{
    width: 450px;
    height: auto;
    border-radius: 30px;
    margin: auto;
    background-color: #eeeeee;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.5);
    padding: 20px;
}


.info-container .user-info{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 40px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
}

.info-container .field-container text{
    font-weight: bold;
}

.info-container .user-info text{
    margin-top: auto;
}

.credits{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 10px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
    margin-left: 60px;
}

.email{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 10px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
    margin-left: 77px;
}

.account-type{
    height: 30px;
    width: 275px;
    background-color: #fff;
    margin-left: 10px;
    margin-top: -6px;
    border-radius: 10px;
    padding-top: 5px;
    margin-left: 15px;
}

.info-container .field-container{
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.header-text{
    font-size: 30pt;
    color: #fff;
    font-weight: bold;
    justify-content: center;
  }

.header-text-h2 {
    font-size: 22pt;
    color: #fff;
    font-weight: bold;
    justify-content: center;
  }


.footer-text {
    font-size: 12pt;
    color: #fff;
    font-weight: normal;
    justify-content: center;
    margin: 12px;
  }
.outer-container{
    height: auto;
    width: 90%;
    background-color: #e3e3e3;
    margin-left: 4%;
    padding: 15px;
    border-radius: 10px;
}

.table-container {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #000;
    background-color: #fff;
  }

  .custom-table thead{
    font-size: 15pt;
    color: #426BBC;
    text-align: center;
  }

  .custom-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: wrap;
    font-weight: bold;
  }
  
  .custom-table th,
  .custom-table td {
    font-size: 12pt;
    padding: 8px;
    border: 1px solid #000;
    text-align: center;
    align-items: center;
  }

  .custom-table th{
    font-size: 15pt;
  }

  .custom-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  .custom-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  .rounded-cell-left{
    border-top-left-radius: 8px;
  }

  .rounded-cell-right{
    border-top-right-radius: 8px;
  }

  .custom-table button{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    color: #fff;
    font-weight: bold;
    font-size: 12pt;
    justify-self: center;
    height: 40px;
    margin-left: 20px;
  }

  .custom-table a{

    color: #426BBC;
    text-decoration: underline;
  }
footer{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    min-width: 600px;
    height: 7vh;
    background-color: #426BBC;
    justify-content: space-between;
    margin-top: auto;
  }

footer nav{
  justify-content: center;
}

footer ul{
  margin-left: 10px;
  display: flex;
}


.footer-links{
  color: #fff;
  text-decoration: none;
  margin-left: 15px;
  margin-right: 15px;
}

footer text{
  color: #fff;
}

footer div{
  align-self: center;
  margin-right: 20px;
}

footer div span{
  margin-right: 10px;
  color: #fff;
}

a{
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
