.header {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  
  .header.expanded .triangle {
    transform: rotate(90deg);
  }
  
  .triangle {
    margin-right: 10px;
    transition: transform 0.3s ease-in-out;
    color: #426BBC;
    font-size: 20px;
    line-height: 1; 
  }