.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

.header{
    font-size: 30pt;
    font-weight: bold;
    justify-content: center;
    color: #426BBC;
}

.explanation-section{
    display: flex;
    justify-content: center;
}

.explanation-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 65%;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius: 25px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.6);
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}

.explanation-title{
    font-size: 20pt;
    font-weight: bold;
    color: #426BBC;
}

.levels-container{
    background: linear-gradient(to right, #426BBC, #74A9F5);
    height: auto;
    width: auto;
    margin: 50px;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

.levels-list{
    text-align: left;
    column-count: 2;
    column-gap: 40px;
    padding: 10px;
    justify-content: space-around;
}

.levels-list li{
    font-size: 17pt;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
}

.sample-header{
    color: #000;
    font-size: 20pt;
    font-style: italic;
    font-weight: bold;
    text-align: center;
}

.level-example-container{
    display: flex;
    align-items: left;
    margin-left: 7%;
    margin-right: 7%;
}

.level-example-list{
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}

.level-example-list li{
    font-size: 20pt;
    font-weight: bold;
    color: #426BBC;
}

.level-example-list p{
    color: #000;
    font-size: 13pt;
}

.all-modules-container{
    display: flex;
    justify-content: center;
}

.module-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 65%;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.6);
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    margin-left: -50px;
}

.modules-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.module-container li{
    font-size: 20pt;
    font-weight: bold;
    color: #426BBC;
}

.module-container p{
    color: #000;
}

.about-us-container{
    display: flex;
    justify-content: center;
}

.about-us-container div{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 65%;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 0 2px 7px rgba(0,0,0, 0.6);
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}

.about-us-container p{
    color: #000;
    font-size: 15pt;
}